html, body, .App{
  height:100vh;
  margin: 0;
}

.App {
  text-align: center;
  background-color: rgb(16, 16, 16);
}

.svg-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background-image: linear-gradient(0deg, rgb(66, 110, 95), rgb(224, 232, 233));
  animation: fadeIn 1s ease-in;
}

@keyframes slideSide {
  from { transform: translateX(var(--slideStart)); }
  to { transform: translateX(var(--slideEnd)); }
} 

@keyframes fadeIn {
  0% { opacity: 0;}
  100% { opacity: 1; }
} 

@keyframes rollingSide {
  from {
      transform: translateX(var(--slideStart)) rotate(0deg);
      opacity: 0;
  }
  to {
      transform: translateX(var(--slideEnd)) rotate(359deg);
      opacity: 1;
  }
}

#H2 {
  --slideStart: -16px;
  --slideEnd: 0px;
  transform: translateX(var(--slideStart));
  animation: slideSide 1s 1s forwards;
}

#Hacross {
  opacity: 0;
  animation: fadeIn 2s 1s forwards;
}

#L2 {
  --slideStart: -21px;
  --slideEnd: 0px;
  transform: translateX(var(--slideStart));
  animation: slideSide 2s 3s forwards;
}

#E, #O {
  /* opacity: 0; */
  --slideStart: -21px;
  --slideEnd: 0px;
  transform-box: fill-box;
  transform-origin: center;
  opacity: 0;
  animation: rollingSide 2s 3s forwards;
}

#there {
  opacity: 0;
  animation: fadeIn 2s ease 4s forwards;
}